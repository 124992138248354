// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'

export default {
  bindChild(id) {
    const user = firebase.database().ref('users').child(id)
    return user
  },
  bindAll() {
    const users = firebase.database().ref('users')
    return users
  },
  async get(id) {
    const functions = firebase.functions()
    const getUser = functions.httpsCallable('getUser')
    const result = await getUser({ userId: id })
    return result.data
  },
  async update(id, userInfo) {
    const functions = firebase.functions()
    const updateUser = functions.httpsCallable('updateUser')
    const result = await updateUser({
      userId: id,
      userInfo,
    })
    return result.data
  },
  async create(data) {
    const functions = firebase.functions()
    const createUser = functions.httpsCallable('createUser')
    const result = await createUser(data)
    return result.data
  },
  async resetPassword(uid, newPassword) {
    const functions = firebase.functions()
    const resetPassword = functions.httpsCallable('resetPassword')
    const result = await resetPassword({
      userId: uid,
      password: newPassword,
    })
    return result.data
  },
}
