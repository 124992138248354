<template>
  <div>
    <b-modal
      ref="my-modal"
      ok-only
      ok-title="Cancelar"
      ok-variant="outline-dark"
      centered
      size="lg"
      title="Seleccionar usuario"
    >
      <vue-good-table
        :columns="columns"
        :rows="filteredUsers"
        :pagination-options="{
          enabled: true,
          perPage: 10
        }"
        :search-options="{
          enabled: true,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <b-link
            v-if="props.column.field === 'name'"
            variant="primary"
            @click="selectUser(props.row)"
          >
            {{ props.row.name }}
          </b-link>
        </template>
      </vue-good-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

import users from '../../../services/users'

export default {
  components: {
    BModal,
    BLink,
    VueGoodTable,
  },
  props: {
    userType: {
      type: String,
      default: 'Cliente',
    },
  },
  data() {
    return {
      users: [],
      columns: [
        {
          label: 'Nombre',
          field: 'name',
        },
        {
          label: 'Correo electrónico',
          field: 'email',
        },
      ],
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter(u => u.type === this.userType)
    },
  },
  firebase: {
    users: users.bindAll(),
  },
  methods: {
    show() {
      this.$refs['my-modal'].show()
    },
    hide() {
      this.$refs['my-modal'].hide()
    },
    selectUser(user) {
      this.$emit('user-selected', user)
      this.$refs['my-modal'].hide()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
