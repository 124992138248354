// Firebase
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  async getAllByOrganizationId(organizationId) {
    const snapshot = await firebase.database().ref('organizationContacts')
      .orderByChild('organization/id')
      .equalTo(organizationId)
      .once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => {
        const item = resultObj[key]
        item.id = key
        resultArray.push(item)
      })
      return resultArray
    }
    return []
  },
  async getAllByUserId(userId) {
    const snapshot = await firebase.database().ref('organizationContacts')
      .orderByChild('user/id')
      .equalTo(userId)
      .once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => {
        const item = resultObj[key]
        item.id = key
        resultArray.push(item)
      })
      return resultArray
    }
    return []
  },
  async create(userId, userName, organizationId, organizationName) {
    const ref = firebase.database().ref('organizationContacts')
    return ref.push({
      user: {
        id: userId,
        name: userName,
      },
      organization: {
        id: organizationId,
        name: organizationName,
      },
    })
  },
  async delete(id) {
    const ref = firebase.database().ref(`organizationContacts/${id}`)
    return ref.remove()
  },
}
