<template>
  <div>
    <b-card title="Años con Indicadores">
      <div class="text-center">
        <b-spinner
          v-show="loading"
          style="width: 3rem; height: 3rem;"
          class="mt-4 mb-3"
        />
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item
          v-for="y in years"
          :key="y"
        >
          {{ y }}
        </b-list-group-item>
        <b-list-group-item>
          <b-form inline>
            <b-form-input
              v-model="newYear"
              :disabled="working"
              placeholder="año a incluir"
            />
            <b-button
              variant="success"
              class="ml-2"
              :disabled="!isNewYearValid"
              @click="onAddClick"
            >
              Añadir
              <b-spinner
                v-if="working"
                small
              />
            </b-button>
          </b-form>
        </b-list-group-item>
        <b-link
          :to="{ name: 'organizations-view-indicators', params: { id: organizationId } }"
          class="mt-2"
        >
          Ir a ficha de Indicadores
          <feather-icon
            icon="ArrowRightCircleIcon"
            size="18"
          />
        </b-link>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BForm,
  BFormInput,
  BButton,
  BSpinner,
  BLink,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import meassurements from '../../../services/meassurements'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BForm,
    BFormInput,
    BButton,
    BSpinner,
    BLink,
  },
  props: {
    organizationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      years: [],
      newYear: '',
      loading: false,
      working: false,
    }
  },
  computed: {
    isNewYearValid() {
      return this.newYear !== '' && !this.years.includes(this.newYear)
    },
  },
  async mounted() {
    await this.fetchYears()
  },
  methods: {
    async fetchYears() {
      this.loading = true
      try {
        this.years = await meassurements.getYearsByOrganizationId(this.organizationId)
      } catch (err) {
        this.showNotification('Error al obtener años (indicadores)', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.loading = false
      }
    },
    async onAddClick() {
      this.working = true
      try {
        const result = await meassurements.bulkAddByOrganizationAndYear(this.organizationId, this.newYear)
        if (result.data.error) {
          this.showNotification('Error al añadir indicadores', result.data.error, 'AlertCircleIcon', 'danger')
        } else {
          this.showNotification('Listo', 'Se añadieron indicadores para el año', 'CheckIcon', 'success')
          this.newYear = ''
          this.fetchYears()
        }
      } catch (err) {
        this.showNotification('Error al añadir indicadores para ese año', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.working = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
