<template>
  <div>
    <b-card title="Logo">
      <b-media vertical-align="top">
        <template #aside>
          <b-img
            :src="img"
            blank-color="#ccc"
            width="120"
            thumbnail
          />
        </template>
        <div>
          <b-row>
            <b-col md="12">
              <b-button
                variant="primary"
                :disabled="uploading || deleting"
                @click="onChangeButtonClick"
              >
                <feather-icon
                  v-if="!uploading"
                  class="mr-1"
                  icon="UploadIcon"
                />
                <b-spinner
                  v-if="uploading"
                  small
                />
                Cambiar
              </b-button>
              <input
                ref="input1"
                type="file"
                style="display: none"
                accept="image/*"
                @change="uploadAndUpdate"
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button
                v-show="logoUrl != ''"
                v-b-modal.modal-danger
                class="mt-1"
                :disabled="uploading || deleting"
                variant="outline-danger"
              >
                <feather-icon
                  v-if="!deleting"
                  class="mr-1"
                  icon="TrashIcon"
                />
                <b-spinner
                  v-if="deleting"
                  small
                />
                Eliminar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-media>
    </b-card>

    <b-modal
      id="modal-danger"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      ok-title="Eliminar logo"
      cancel-title="Cancelar"
      modal-class="modal-danger"
      centered
      title="¡Cuidado!"
      @ok="onConfirmDelete"
    >
      <b-card-text>
        <p>Esta acción es de efecto inmediato y no puede deshacerse.</p>
        <p>
          Está seguro que desea continuar
          y eliminar el logo de esta organización.
        </p>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import organizations from '@/services/organizations'

import {
  BCard,
  BButton,
  BMedia,
  BImg,
  BRow,
  BCol,
  BModal,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BMedia,
    BImg,
    BRow,
    BCol,
    BModal,
    BCardText,
    BSpinner,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      uploading: false,
      deleting: false,
      logoUrl: '',
    }
  },
  computed: {
    img() {
      return (this.logoUrl) ? this.logoUrl : require('@/assets/images/avatars/default.png')
    },
  },
  async mounted() {
    this.loading = true
    try {
      this.logoUrl = await organizations.getLogoURL(this.organizationId, this.organization.logo)
    } catch {
      // do nothing
    } finally {
      this.loading = false
    }
  },
  methods: {
    onChangeButtonClick() {
      this.$refs.input1.click()
    },
    async uploadAndUpdate(event) {
      this.uploading = true
      try {
        const file = event.target.files[0]
        const url = await organizations.updateLogo(this.organizationId, file.name, file)
        this.logoUrl = url
        this.showNotification('Listo', 'Se actualizó el logo', 'CheckIcon', 'success')
        this.$emit('logo-update', file.name)
      } catch (e) {
        this.showNotification('Error al actualizar logo', e.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.uploading = false
      }
    },
    async onConfirmDelete() {
      this.deleting = true
      try {
        await organizations.deleteLogo(this.organizationId)
        this.logoUrl = ''
        this.showNotification('Listo', 'Se eliminó el logo', 'CheckIcon', 'success')
        this.$emit('logo-update', '')
      } catch (e) {
        this.showNotification('Error al eliminar logo', e.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.deleting = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
