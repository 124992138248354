<template>
  <div>
    <b-card title="Contactos">
      <div class="text-center">
        <b-spinner
          v-show="loading"
          style="width: 3rem; height: 3rem;"
          class="mt-4 mb-3"
        />
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item
          v-for="c in contacts"
          :key="c.id"
          class="d-flex justify-content-between align-items-center"
        >
          {{ c.user.name }}
          <b-link
            class="text-muted"
            href="#"
            @click="onDeleteContactClick(c)"
          >
            x
          </b-link>
        </b-list-group-item>
      </b-list-group>
      <b-button
        class="mt-1"
        variant="success"
        :disabled="saving"
        @click="selectContact"
      >
        Añadir...
        <b-spinner
          v-if="saving"
          small
        />
      </b-button>
    </b-card>
    <user-select
      ref="contact-select"
      :show-modal="showUserSelect"
      user-type="Cliente"
      @user-selected="onUserSelected"
    />
  </div>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BButton,
  BSpinner,
  BLink,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserSelect from './UserSelect.vue'

import organizationContacts from '../../../services/organizationContacts'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
    BSpinner,
    BLink,
    UserSelect,
  },
  props: {
    organizationId: {
      type: String,
      default: '',
    },
    organizationName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contacts: [],
      loading: false,
      saving: false,
      showUserSelect: false,
      deleting: false,
    }
  },
  async mounted() {
    await this.fetchContacts()
  },
  methods: {
    async fetchContacts() {
      this.loading = true
      try {
        this.contacts = await organizationContacts.getAllByOrganizationId(this.organizationId)
      } catch (err) {
        this.showNotification('Error al obtener lista de contactos', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.loading = false
      }
    },
    selectContact() {
      this.$refs['contact-select'].show()
    },
    async onUserSelected(user) {
      this.saving = true
      try {
        await organizationContacts.create(user.id, user.name, this.organizationId, this.organizationName)
        this.showNotification('Listo', 'Se añadió un nuevo contacto', 'CheckIcon', 'success')
        this.fetchContacts()
      } catch (err) {
        this.showNotification('Error al añadir contacto', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.saving = false
      }
    },
    onDeleteContactClick(c) {
      this.$bvModal.msgBoxConfirm(`¿Está seguro de querer remover a ${c.user.name} como contacto?`, {
        title: `Remover a ${c.user.name}`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Sí',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async confirmed => {
          if (confirmed) {
            await this.doDeleteContact(c)
            this.showNotification('Listo', 'Se removió el contacto', 'CheckIcon', 'success')
          }
        })
        .catch(err => {
          this.showNotification('Error', err.message, 'AlertCircleIcon', 'danger')
        })
    },
    async doDeleteContact(c) {
      this.deleting = true
      try {
        await organizationContacts.delete(c.id)
        this.fetchContacts()
      } catch (err) {
        this.showNotification('Error el remover contacto', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.deleting = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
