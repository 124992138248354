// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export default {
  async getWithBenchmarks(orgId, indictrId) {
    const functions = firebase.functions()
    const getMeassurementsWithBenchmarks = functions.httpsCallable('getMeassurementsWithBenchmarks')
    const result = await getMeassurementsWithBenchmarks({
      organizationId: orgId,
      indicatorId: indictrId,
    })
    return result
  },
  async save(orgId, indictrId, data) {
    const functions = firebase.functions()
    const saveMeassurements = functions.httpsCallable('saveMeassurements')
    const result = await saveMeassurements({
      organizationId: orgId,
      indicatorId: indictrId,
      meassurements: data,
    })
    return result
  },
  async getYearsByOrganizationId(orgId) {
    const snapshot = await firebase.database().ref('meassurements')
      .orderByChild('organizationId')
      .equalTo(orgId)
      .once('value')
    if (snapshot.exists()) {
      const resultObj = snapshot.val()
      const resultArray = []
      Object.keys(resultObj).forEach(key => {
        const item = resultObj[key]
        item.organizationId = key
        resultArray.push(item)
      })
      return resultArray.map(m => m.year).filter(onlyUnique)
    }
    return []
  },
  async bulkAddByOrganizationAndYear(orgId, y) {
    const functions = firebase.functions()
    const createMeassurements = functions.httpsCallable('createMeassurements')
    const result = await createMeassurements({
      organizationId: orgId,
      year: y,
    })
    return result
  },
}
