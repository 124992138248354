// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

export default {
  bindAll() {
    const result = firebase.database().ref('networks')
    return result
  },
  create(data) {
    const ref = firebase.database().ref('networks')
    ref.push(data)
  },
}
