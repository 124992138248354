// Firebase
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

export default {
  bindAll() {
    const result = firebase.database().ref('countries')
    return result
  },
}
