<template>
  <div>
    <custom-app-breadcrumb
      :page-title="organizationName"
      :breadcrumb="breadcrumb"
    />
    <div class="text-center">
      <b-spinner
        v-show="loading"
        style="width: 5rem; height: 5rem;"
        class="mt-4 mb-3"
      />
    </div>
    <b-row>
      <b-col
        md="12"
      >
        <b-card v-if="organization">
          <b-form>
            <validation-observer
              ref="simpleRules"
              v-slot="{ invalid }"
            >
              <b-row>
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Nombre de la Organización"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        v-model="organization.name"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Ciudad"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Ciudad"
                      rules="required"
                    >
                      <b-form-input
                        v-model="organization.city"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="País"
                  >
                    <b-form-select
                      v-model="selectedCountry"
                      :options="countryOptions"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Sector"
                  >
                    <b-form-select
                      v-model="organization.sector"
                      :options="sectorOptions"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Red"
                  >
                    <b-form-select
                      v-model="selectedNetwork"
                      :options="networkOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <b-form-group label="Asesor">
                    <b-input-group v-show="organization.advisor.id !== ''">
                      <b-form-input
                        v-model="organization.advisor.name"
                        disabled
                      />
                      <b-input-group-append>
                        <b-button @click="clearAdvisor">
                          x
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-input-group v-show="organization.advisor.id === ''">
                      <b-form-input disabled />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="selectAdvisor"
                        >
                          +
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="Estrellas">
                    <b-form-rating
                      v-model="currentAccreditation.stars"
                      stars="7"
                      no-border
                      show-clear
                      show-value
                      inline
                      variant="warning"
                    />
                  </b-form-group>
                  <b-form-group label="Fecha de acreditación">
                    <b-form-datepicker
                      id="datepicker-buttons"
                      v-model="currentAccreditation.accreditationDate"
                      today-button
                      reset-button
                      close-button
                      locale="en"
                    />
                  </b-form-group>
                  <b-form-checkbox
                    v-model="isOrgActive"
                    :value="true"
                    name="check-button"
                    switch
                    inline
                    class="mt-2"
                  >
                    {{ isOrgActiveText }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-button
                variant="success"
                :disabled="saving || invalid"
                @click="onSaveClick"
              >
                Guardar
                <b-spinner
                  v-if="saving"
                  small
                />
              </b-button>
            </validation-observer>
          </b-form>
        </b-card>
      </b-col>
      <user-select
        ref="user-select"
        :show-modal="showUserSelect"
        user-type="Asesor"
        @user-selected="onUserSelected"
      />
    </b-row>
    <b-row>
      <b-col md="6">
        <year-list-card
          v-if="organization"
          :organization-id="organizationId"
        />
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <contact-list-card
              v-if="organization"
              :organization-id="organizationId"
              :organization-name="organization.name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <logo-card
              v-if="organization"
              :organization="organization"
              :organization-id="organizationId"
              @logo-update="onLogoUpdate"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormRating,
  BFormDatepicker,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserSelect from './UserSelect.vue'
import YearListCard from './YearListCard.vue'
import LogoCard from './LogoCard.vue'
import ContactListCard from './ContactListCard.vue'

import CustomAppBreadcrumb from '../../../layouts/components/CustomAppBreadcrumb.vue'

import organizations from '../../../services/organizations'
import countries from '../../../services/countries'
import networks from '../../../services/networks'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormRating,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    CustomAppBreadcrumb,
    UserSelect,
    YearListCard,
    LogoCard,
    ContactListCard,
  },
  data() {
    return {
      organization: null,
      isOrgActive: true,
      currentAccreditation: {
        stars: 0,
        accreditationDate: '',
      },
      countries: [],
      networks: [],
      selectedCountry: '',
      selectedNetwork: '',
      selectedAdvisor: '',
      sectorOptions: [
        'Educación',
        'Salud',
      ],
      loading: false,
      saving: false,
      required,
      showUserSelect: false,
    }
  },
  computed: {
    organizationName() {
      return (this.organization) ? this.organization.name : ''
    },
    breadcrumb() {
      return [
        {
          text: 'Admin de Sistema',
          active: true,
        },
        {
          text: 'Instituciones',
          to: { name: 'admin-organizations' },
        },
        {
          text: (this.organization) ? this.organization.name : '',
          active: true,
        },
      ]
    },
    countryOptions() {
      return this.countries.map(c => ({ value: c['.key'], text: c.name }))
    },
    networkOptions() {
      const netOptns = this.networks.map(n => ({ value: n['.key'], text: n.name }))
      let result = [{ value: '', text: '(ninguna)' }]
      result = result.concat(netOptns)
      return result
    },
    organizationId() {
      return this.$route.params.id
    },
    isOrgActiveText() {
      if (!this.organization) {
        return ''
      }

      return (this.isOrgActive) ? 'Organización Activa' : 'Organización Inactiva'
    },
  },
  firebase: {
    countries: countries.bindAll(),
    networks: networks.bindAll(),
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        this.organization = await organizations.get(this.$route.params.id)
        this.selectedNetwork = this.organization.network.id
        this.selectedCountry = this.organization.country.id
        this.selectedAdvisor = this.organization.advisor.id
        this.isOrgActive = !this.organization.isInactive
        this.currentAccreditation = (this.organization.currentAccreditation)
          ? this.organization.currentAccreditation
          : {
            stars: 0,
            accreditationDate: '',
          }
      } catch (err) {
        this.showNotification('Error al leer datos de la institución', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.loading = false
      }
    },
    clearAdvisor() {
      this.organization.advisor = { id: '', name: '' }
    },
    selectAdvisor() {
      this.$refs['user-select'].show()
    },
    async onSaveClick() {
      this.saving = true
      this.organization.country = (this.selectedCountry !== '')
        ? this.countries.map(c => ({ id: c['.key'], name: c.name })).find(c => c.id === this.selectedCountry)
        : { id: '', name: '' }
      this.organization.network = (this.selectedNetwork !== '')
        ? this.networks.map(n => ({ id: n['.key'], name: n.name })).find(n => n.id === this.selectedNetwork)
        : { id: '', name: '' }
      this.organization.isInactive = !this.isOrgActive
      this.organization.currentAccreditation = this.currentAccreditation

      try {
        const result = await organizations.update(this.$route.params.id, this.organization)
        if (result.data && result.data.result === 'ok') {
          this.showNotification('Listo', 'Se actualizó la información de la organización', 'CheckIcon', 'success')
        } else if (result.data && result.data.error) {
          this.showNotification('Error al guardar la organización', result.data.error, 'AlertCircleIcon', 'danger')
        } else {
          this.showNotification('Error al guardar la organización', '', 'AlertCircleIcon', 'danger')
        }
      } catch (err) {
        this.showNotification('Error al guardar la organización', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.saving = false
      }
    },
    onUserSelected(user) {
      const usrInfo = { id: user.id, name: user.name }
      this.organization.advisor = usrInfo
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
    onLogoUpdate(filename) {
      this.organization.logo = filename
    },
  },
}
</script>
